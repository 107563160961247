<template>
  <div class="fonts">
    <a-layout-content>
      <div class="mb-3 text-center">
        <h1>Modals</h1>
      </div>
      <div class="mb-3">
        <a-button type="primary" @click="showModal">Open Modal</a-button>
        <a-modal v-model:visible="visible" title="Basic Modal" @ok="handleOk">
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p> 
          <template #footer>
            <a-button key="back" type="secondary" @click="this.visible=false">Cancel</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">Submit</a-button>
          </template>
        </a-modal>
      </div>
    </a-layout-content>
  </div>
</template>

<script>

import {  ref } from 'vue';

export default {
  setup() {
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = e => {
      console.log(e);
      visible.value = false;
    };

    return {
      visible,
      showModal,
      handleOk,
    };
  },
}

</script>
