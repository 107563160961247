<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item>Home</a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin">Admin</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin/companies">Companies</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ company.companyName }}</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />
    
<a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }" class="mb-2">
  <a-col :xs="24">
    <a-button class="mb-2" type="secondary" @click="$router.push('/admin/companies')">Go Back</a-button>

    <a-card :title="company.companyName" class="h2" >
      <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <a-col class="mb-2" :xs="24">
          <b>Telephone Number</b><br/>
            <span v-if="!isEditing">{{ company.telephoneNumber }}</span>
            <a-input v-else v-model:value="company.telephoneNumber" />
        </a-col>
        <a-col class="mb-2" :xs="24">
          <b>Company Registration Number</b><br/>
            <span v-if="!isEditing">{{ company.companyRegNumber }}</span>
            <a-input v-else v-model:value="company.companyRegNumber" />
        </a-col>
        <a-col class="mb-2" :xs="24">
          <b>Phyiscal Address</b><br/>
            <span v-if="!isEditing">{{ company.physicalAddress }}</span>
            <a-input v-else v-model:value="company.physicalAddress" />
        </a-col>
        <a-col class="mb-2" :xs="24">
          <b>Postal Address</b><br/>
            <span v-if="!isEditing">{{ company.postalAddress }}</span>
            <a-input v-else v-model:value="company.postalAddress" />
        </a-col>
        
    </a-row>
    </a-card>
  </a-col>
</a-row>
  </div>
</template>

<script>
import axios from "axios";
import { notification } from 'ant-design-vue';

export default {
  props: {
    id: { required: true },
  },  
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  data() {
    return {
      isEditing: false,
      company: {},
      oldData: {},
      logoFile: "",
    };
  },
  created() {
    //const headers = { "Content-Type": "application/json" };
    axios
      .get(
        `${this.api}/Company/` + this.$route.params.id
          
      )
      .then((response) => {
        this.company = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    handleFileUpload() {
      this.logoFile = this.$refs.file.files[0];
    },
    updateData()
    {
      axios
        .put(`${this.api}/Client`, 
          {
            "companyName": this.company.companyName,
            "tradingName": this.company.tradingName,
            "physicalAddress": this.company.physicalAddress,
            "postalAddress": this.company.postalAddress,
            "telephoneNumber": this.company.telephoneNumber,
            "companyRegNumber": this.company.companyRegNumber,
            "logoFileName": null
          }
        )
        .then( () => {
          notification.open({
            message: "Company Saved.",
            placement: "bottomRight"
          });
        })
        .catch( () => {
        notification.open({
          message: "Error Saving Company.",
          placement: "bottomRight"
        });
        });
    }
  },
};
</script>
