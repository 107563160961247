<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin">Admin</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>Template Fields</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />
    
    <AdminMenu/>
    
    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="24">
        <a-card title="Template Fields" type="secondary">        
            
        <template #extra>
          <a-button v-if="!addingField" type="primary" @click="addField">Add Field</a-button>
         </template>  
          <a-table :dataSource="templateFields" :columns="columns">   
            <template #field="{ record }">
              <span v-if="editingFields[record.id] || record.isNew">
                <a-input type="text" v-model:value="record.templateFieldName" />
              </span>
              <span v-else>{{ record.templateFieldName }}</span>
            </template>
            <template #description="{ record }">
              <span v-if="editingFields[record.id] || record.isNew">
                <a-input type="text" v-model:value="record.templateFieldDescription" />
              </span>
              <span v-else>{{ record.templateFieldDescription }}</span>
            </template>
            <template #action="{ record }">
              <span v-if="record.isNew">
                <a-button type="success" @click="createField(record)">Submit</a-button>
                <a-button class="ml-1 ant-btn-danger" @click="cancelField(record)">Cancel</a-button>
              </span>
              <span v-else-if="editingFields[record.id] == null || !editingFields[record.id]">
                <a-button @click="editingFields[record.id] = true">Edit</a-button>
                              
                <a-popconfirm
                  title="Are you sure delete this field?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="deleteField(record.id)"
                >
                <a-button class="ant-btn-danger">X</a-button>
                </a-popconfirm>
              </span>
              <span v-else>
                <a-button type="success" @click="editingFields[record.id] = false; saveField(record.id)">Save</a-button>
                <a-button class="ml-1 ant-btn-danger" @click="editingFields[record.id] = false">Cancel</a-button>
              </span>
            </template>
          </a-table>      
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>


<script>

import { notification } from 'ant-design-vue';

import axios from "axios";

import AdminMenu from "../../components/AdminMenu.vue"

export default {
  components:
  {
    AdminMenu
  },
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  props: [
    "id"
  ],
  data() {
    return {
      templateFields: [],
      editingFields: [],
      addingField: false
    };
  },
  setup() {
    return {
      columns: [
        {
          title: 'Field Name',
          dataIndex: 'templateFieldName',
          key: 'templateFieldName',
          slots: { customRender: 'field' },
        },
        {
          title: 'Description',
          dataIndex: 'templateFieldDescription',
          key: 'templateFieldDescription',
          slots: { customRender: 'description' },
        },
        {
          title: 'Type',
          dataIndex: 'templateFieldType',
          key: 'templateFieldType',
        },        
        {
          title: 'Actions',
          key: 'action',
          slots: { customRender: 'action' },
        },  
      ]
    }
  },
  created() {
    //const headers = { "Content-Type": "application/json" };
    axios
      .get(
        `${this.api}/Company/templates/masters`
      )
      .then((response) => {
        this.templateFields = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: 
  {
    refresh()
    {
      axios
      .get(
        `${this.api}/Company/templates/masters`
      )
      .then((response) => {
        this.templateFields = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    saveField(id)
    {
      var field = this.templateFields.find(v => v.id == id);

      axios
      .put(
        `${this.api}/Company/templates/masters/` + id,
        {
          templateFieldDescription: field.templateFieldDescription,
          templateFieldName: field.templateFieldName,
          templateFieldType: field.templateFieldType
        }
      )
      .then(() => {
        notification.open({
          message: "Field Saved.",
          placement: "bottomRight"
        });
        this.refresh();
      })
      .catch((error) => {
        notification.open({
          message: "Error Saving Field.",
          placement: "bottomRight"
        });
        console.log(error);
      });
    },
    addField()
    {
      this.addingField = true;
      this.templateFields.push({
        templateFieldName: "",
        templateFieldDescription: "",
        templateFieldType: "text",
        isNew: true
      })
    },
    cancelField(record)
    {
      this.templateFields.splice(this.templateFields.indexOf(record), 1);
        this.addingField = false;
    },
    createField(record)
    {
      axios
      .post(
        `${this.api}/Company/templates/masters/`,
        {
          templateFieldName: record.templateFieldName,
          templateFieldDescription: record.templateFieldDescription,
          templateFieldType: record.templateFieldType
        }
      )
      .then(() => {
        notification.open({
          message: "Field Created.",
          placement: "bottomRight"
        });
        this.addingField = false;
        this.refresh();
      })
      .catch((error) => {
        notification.open({
          message: "Error creating field.",
          placement: "bottomRight"
        });
        console.log(error);
      });
    },
    deleteField(id)
    {
      axios
      .delete(
        `${this.api}/Company/templates/masters/` + id
      )
      .then(() => {
        notification.open({
          message: "Field Deleted.",
          placement: "bottomRight"
        });
        this.refresh();
      })
      .catch((error) => {
        console.log(error);
        notification.open({
          message: "Unable to delete Field. It may be in use",
          placement: "bottomRight"
        });
      })
      
    }
  }
};
</script>
