<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin">Admin</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin/templates">Templates</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ template.templateName }}</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />

    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="24">
        <a-button class="mb-2" type="secondary" @click="$router.push('/admin/categories')">Back</a-button>

        <a-card :title="template.templateName" type="secondary">
          <template #extra>
            <a @click="doEdit = true" href="#">Edit</a>
          </template>
          <a-row>
            <strong>Name:</strong>&nbsp;&nbsp;<code>{{template.templateName}}</code>
          </a-row>
          <a-row v-if="template.templateFileName == null">
            <input type="file" id="file" ref="file" @change="uploadFile" />
          </a-row>
          <a-row v-else>
            <strong>File:</strong>&nbsp;&nbsp;&nbsp;&nbsp;<code>{{template.templateFileName}}</code>
          </a-row>
          <br/>
          <a-row>
            <a-col>
            <strong>Description:</strong><br/>
            <code>{{template.templateDescription}}</code>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row class="mt-2">
      <a-col :xs="24">
        <a-card title="Fields">
          <a-transfer
            :data-source="allFields"
            :list-style="{
              width: '45%',
              height: '300px',
            }"
            :titles="['Available', 'Selected']"
            :target-keys="targetKeys"
            @change="handleFieldChange"
          >
            <template #render="item">
              <span class="custom-item">{{ item.title }} - {{ item.description }}</span>
            </template>
          </a-transfer>
        </a-card>
      </a-col>
    </a-row>
  </div>

  <a-modal v-model:visible="doEdit" :title="'Edit ' + template.templateName " @cancel="doEdit=false" @ok="update">
    <a-row>
      <label>Template Name</label><br/>
      <a-input v-model:value="editTemplate.templateName" />
    </a-row>
    <br/>
     <a-row>
      <label>Template Description</label><br/>
      <a-input v-model:value="editTemplate.templateDescription" />
    </a-row>
  </a-modal>
</template>


<script>


import axios from "axios";
import { notification } from 'ant-design-vue';

export default {
  components:
  {
  },
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  props: [
    "id"
  ],
  data() {
    return {
      template: {},
      fileList: [],
      chosenFields: [],
      allFields: [],
      targetKeys: [],

      doEdit: false,
      editTemplate: {}
    };
  },
  setup() {
    const handleChange = () => {
      this.uploadFile();
    };
    return {
      handleChange,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData()
    {//const headers = { "Content-Type": "application/json" };
      axios
        .get(
          `${this.api}/Company/templates/` + this.id
        )
        .then((response) => {
          this.template = response.data;
          this.editTemplate = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
      .get(
        `${this.api}/Company/templates/fields/` + this.id
      )
      .then((response) => {
        response.data.forEach(e => {
          this.targetKeys.push(e.id.toString());
        });
        axios
          .get(
            `${this.api}/Company/templates/masters`
          )
          .then((response) => {
            response.data.forEach(e => {

              var f = {
                key: e.id.toString(),
                title: e.templateFieldName,
                description: e.templateFieldDescription,
                chosen: true
              };
              this.allFields.push(f);
            });
          })
          .catch((error) => {
            console.log(error);
          });

      })
      .catch((error) => {
        console.log(error);
      });
      
    },
    uploadFile() {

      this.template.File = this.$refs.file.files[0];

      const formData = new FormData();
      formData.append('templateId', this.template.id);
      formData.append('templateVersion', 0);
      formData.append('File', this.template.File);

      axios({
        url: `${this.api}/Company/template/upload`,
        method: "PUT",
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })      
      .then(() => {
        notification.open({
          message: "Saved",
          placement: "bottomRight"
        });
      })
      .catch((error) => {
        console.log(error);
      });
    },
    handleFieldChange(keys, direction, moveKeys) 
    {
      this.targetKeys = keys;

      if(direction == "right")
      {
        this.addLinks(moveKeys);
      }
      if(direction == "left")
      {
        this.delLinks(moveKeys);
      }
    },
    addLinks(keys)
    {
      keys.forEach(k => {
        axios
          .post(this.api + "/Company/templates/masters/templateandfieldlinks",
          {
            templateId: parseInt(this.id),
            templateFieldId: parseInt(k)
          });
      });
    },
    delLinks(keys)
    {
      keys.forEach(k => {
        var id = this.template.templateFieldLink.find(c => c.templateFieldId == parseInt(k)).id;
        axios
          .delete(this.api + "/Company/templates/masters/templateandfieldlinks/" + id);
        
      });
    },
    update()
    {
      axios
        .put(this.api + "/Company/templates/" + this.id,
        {
          templateName: this.editTemplate.templateName,
          templateDescription: this.editTemplate.templateDescription
        })
        .then(() => {
          notification.open({
            message: "Successfully saved the template.",
            type: 'success'
          });
          this.doEdit = false;
        })
        .catch(() => {          
          notification.open({
            message: "There was an error saving the template.",
            type: 'error'
          })
        });
    }
  },
};
</script>
