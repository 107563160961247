import { createStore } from "vuex";
import axios from "axios";
import { notification } from 'ant-design-vue';


let api;
async function getAPI () { 
  await axios.get(`/conf.json`).then(res => api = res.data.api); 

  api = api.find(x => x.isActive).url;
  
}

getAPI();


export default createStore({
  state: {
    user: null,
    companyId: null,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      localStorage.setItem("user", JSON.stringify(userData));
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userData.token}`;
    },
    SET_COMPANY_ID(state, companyId) {
      state.companyId = companyId;
      localStorage.setItem("companyId", companyId);
    }
  },
  actions: {
    register({ commit }, credentials) {
      return axios
        .post(api + "/Auth/register", credentials)
        .then(({ data }) => {
          
          commit("SET_USER_DATA", data);
        })
        .catch(error => {
          
          notification.open({
            message: error.message,
            type: 'error'
          });
        })
    },
    login({ commit }, credentials) {
      
      return axios
        .post(api + "/Auth/login", credentials)
        .catch(error => {
          console.error("There was an error!", error);
          Object.values(error.response.data.errors).forEach(v => {
              
            notification.open({
              message: v,
              type: 'error'
            });
          });
          return false;
        })
        .then(({ data }) => {
          
          commit("SET_USER_DATA", data);
          notification.open({
            message: "Logged in",
            type: 'error'
          });
          return true;
        })
    },
    logout() {
      localStorage.removeItem("user");
      location.reload();
      notification.open({
        message: "Logged out",
        type: 'success'
      });
    },
    getcompanys(){
      return axios
        .get(api + "company")
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          
          Object.values(error.response.data.errors).forEach(v => {
              
            notification.open({
              message: v,
              type: 'error'
            });
          });
        })
    },
    getTemplates()
    {
      return axios
        .get(api + "/client/templates")
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          Object.values(error.response.data.errors).forEach(v => {
              
            notification.open({
              message: v,
              type: 'error'
            });
          });
          
        })
        .then(() => {
          
        })
    },
    setCompany({ commit }, companyId) 
    {      
      commit("SET_COMPANY_ID", companyId);
    }
  },
  modules: {},
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
  },
});
