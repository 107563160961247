import { createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import { notification } from "ant-design-vue";
import moment from "moment";
import axios from "axios";


import "ant-design-vue/dist/antd.css";
import "./style-ant.css";


notification.config({
   placement: 'bottomRight',
   duration: 3,
 });
 

const app = createApp(App)
   .use(store)
   .use(router)
   .use(Antd);



app.config.globalProperties.$filters = {
   standardDate(date) {
     return moment(date).format("DD MMM YYYY");
   },
   standardDateTime(date) {
      return moment(date).format("DD MMM YYYY, hh:mm");
    }
 }


 async function getAPI () { 
   let api;
   await axios.get(`/conf.json`).then(res => api = res.data.api); 


   api = api.find(x => x.isActive).url;
      
   app.provide('APIURL', api);


   app.mount("#app");
}

getAPI();
