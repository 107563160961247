<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item>Home</a-breadcrumb-item>
      <a-breadcrumb-item>{{ company.companyName }}</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />
<a-row class="mb-2">
  <a-col :xs="24">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="Resources">
        
        <a-card title="Template Categories" type="secondary">
          
          <a-tabs v-model:activeKey="activeKeyTemplates" @change="getCategory">

            <a-tab-pane :tab="category.templateCategoryName" v-for="category in categories" v-bind:key="category.id">
             
              <a-row class="templaterow" v-for="template in templates" v-bind:key="template.id">
                <a-col :xs="24" :md="14">
                  <router-link :to="{ name: 'Template', params: { id: template.id }}">{{ template.templateName }}</router-link>
                  <br/>
                  <small :style="{whiteSpace: 'pre-line'}" v-html="template.templateDescription"></small>
                </a-col>
                <a-col :xs="24" :md="2" align="center">
                  <!-- {{ $filters.standardDate( template.templateLastUpdate ) }} -->
                </a-col>
                <a-col :xs="24" :md="2" align="center">
                  <!-- v {{ template.templateVersion }} -->
                </a-col>
                <a-col :xs="24" :md="2" align="center">
                  <a-button-group>
                    <router-link class="ant-btn" :to="{ name: 'Template', params: { id: template.id }}">Manage</router-link>
                  </a-button-group>
                </a-col>

              </a-row> 
              <a-row class="mb-2" v-if="!searchingTemplates && templates.length == 0">
                <a-col :xs="24" :md="24">
                  No templates found...
                </a-col>
              </a-row>
              
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Company Details">

        <a-card :title="company.companyName" class="h2" >
          <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
            <a-col class="mb-2" :xs="24">
              <b>Telephone Number</b><br/>
                <span v-if="!isEditing">{{ company.telephoneNumber }}</span>
            </a-col>
            <a-col class="mb-2" :xs="24">
              <b>Company Registration Number</b><br/>
                <span v-if="!isEditing">{{ company.companyRegNumber }}</span>
            </a-col>
            <a-col class="mb-2" :xs="24">
              <b>Phyiscal Address</b><br/>
                <span v-if="!isEditing">{{ company.physicalAddress }}</span>
            </a-col>
            <a-col class="mb-2" :xs="24">
              <b>Postal Address</b><br/>
                <span v-if="!isEditing">{{ company.postalAddress }}</span>
            </a-col>
          </a-row>
        </a-card>        

      </a-tab-pane>
      
    </a-tabs>
  </a-col>
</a-row>

  </div>
</template>

<script>
import axios from "axios";
import { notification } from 'ant-design-vue';
import store from '../store';

export default {
  props: {
    id: { required: true },
  },
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  data() {
    return {
      isEditing: false,
      company: {},
      oldData: {},
      logoFile: "",
      templates: [],
      queries: [],
      isSaving: false,

      isUploadLogo: false,

      selCategory: "",
      categories: [],
      searchingTemplates: false
    };
  },
  mounted() {
    //const headers = { "Content-Type": "application/json" };
    axios
      .get(
        `${this.api}/Client`
      )
      .then((response) => {
        this.company = response.data;
        store.dispatch('setCompany', response.data.id);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${this.api}/Client/templatecategories`
      )
      .then((response) => {
        this.categories = response.data;
        this.getCategory(response.data[0].id);
      })
      .catch((error) => {
        console.log(error);
      });

  },
  methods: {
    getCategory(id)
    {
      this.templates = [];
      this.selCategory = id;
      this.category = name;
      this.searchingTemplates = true;
      axios
        .get(
          `${this.api}/Company/templates/category/${id}`
        )
        .then((response) => {
          this.templates = response.data;
          this.searchingTemplates = false;
        })
        .catch((error) => {
          console.log(error);
          this.searchingTemplates = false;
        });
    },
    uploadLogo() {
      let formData = new FormData();
      formData.append("file", this.logoFile);
      axios
        .post(`${this.api}/Company/logo`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          notification.open({
            message: "Logo Uploaded.",
            placement: "bottomRight"
          })
        })
        .catch(function () {
          notification.open({
            message: "Error uploading file.",
            placement: "bottomRight"
          })
        });
    },
    handleFileUpload() {
      this.logoFile = this.$refs.file.files[0];
    },
    updateData()
    {
      this.isSaving = true;
      axios
        .put(`${this.api}/Client`, 
          {
            "companyName": this.company.companyName,
            "tradingName": this.company.tradingName,
            "physicalAddress": this.company.physicalAddress,
            "postalAddress": this.company.postalAddress,
            "telephoneNumber": this.company.telephoneNumber,
            "companyRegNumber": this.company.companyRegNumber,
            "logoFileName": null
          }
        )
        .then( () => {
          alert("Updated");
          this.isSaving = false;
        })
        .catch( () => {
          alert("Oh no!");
          this.isSaving = false;
        });
    },
    templateDownload(templateid) {
      notification.open({
            message: "Preparing template file...",
            placement: "bottomRight"
          })

      axios({
        url: `${this.api}/company/word/` + this.company.id + "/" + templateid,
        method: "GET",
        headers: {
          Accept: "*/*"         
        },
        responseType: "arraybuffer"
      }).then((response) => {
        var fileURL = URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        
        fileLink.setAttribute("download", this.templates.find(x => x.id == templateid).templateFileName);
        document.body.appendChild(fileLink);

        fileLink.click();
        notification.open({
            message: "Template File is ready for download.",
            placement: "bottomRight"
          })
      })
      .catch(() => {
        notification.open({
          message: "The requested file was not found. Please contact an Administrator.",
          type: 'error'
        })
      });
    },
  },
};
</script>

<style scoped>
.templaterow
{
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.templaterow
{
  padding: 16px 8px;
}

.templaterow:hover
{
  background: #f3f3f3;
}
</style>