<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin">Admin</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin/templates">Templates</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>Create</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />

    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="24">
        <a-card title="Create Template" type="secondary">
          <a-row class="mb-2">
            <label>Template Name</label>
            <a-input v-model:value="templateName" />
            <a-button @click="submit()" class="mt-1">Create</a-button>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import axios from "axios";
import router from "../../router";

export default {
  components:
  {
  },  
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  data() {
    return {
        templateName: ""
    };
  },
  created() {
    
  },
  methods: {
    submit() {
      //const headers = { "Content-Type": "application/json" };
    axios
      .post(
        `${this.api}/Company/template`,
        {
          templateName: this.templateName
        }
      )
      .then((response) => {
        router.push("/admin/template/" + response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    

    }
  },
};
</script>
