<template>
  <a-layout>
    <AppNav></AppNav>
    <router-view />
    <AppFooter></AppFooter>
  </a-layout>
</template>
<script>
import AppNav from "@/components/AppNav.vue";
import AppFooter from "@/components/AppFooter.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    AppNav,
    AppFooter
  },
  created() {
    const userString = localStorage.getItem('user') // grab user data from local storage
    if (userString) {
      // check to see if there is indeed a user
      try
      {
        const userData = JSON.parse(userString) // parse user data into JSON
        this.$store.commit('SET_USER_DATA', userData) // restore user data with Vuex
      }
      catch(ex)
      {
        console.log(ex);
      }
    }
    const companyId = localStorage.getItem('companyId') // grab company idfrom local storage
    if (companyId) {
      // check to see if there is indeed a company
      try
      {
        this.$store.commit('SET_COMPANY_ID', companyId) // restore company data with Vuex
      }
      catch(ex)
      {
        console.log(ex);
      }
    }
    axios.interceptors.response.use(
      response => response, // simply return the response
      error => {
        console.log("Security error: ", error);
        if (error.response.status === 401) {
          // if we catch a 401 error
          this.$store.dispatch('logout') // force a log out
        }
        return Promise.reject(error) // reject the Promise, with the error as the reason
      }
    )
  }
};
</script>
<style>

</style>
