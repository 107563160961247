<template>
  <div>
    <form id="TheForm" @submit.prevent="login">
      <label for="email"> Email: </label>
      <input
        v-model="email"
        type="email"
        name="email"
        class="ant-input"
      />

      <label for="password"> Password: </label>
      <a-input-password
        v-model:value="password"
        name="password"
        class="ant-input"
      />

      <button type="submit" name="button" class="ant-btn ant-btn-primary mt-1">Login</button>
    </form>
    <hr/>
    Don't have an account? <router-link to="/register" class="button"> Register </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      
      this.$store
        .dispatch("login", {
          Email: this.email,
          Password: this.password,
        })
        .then(() => {
          this.$router.push({ name: "Dashboard" });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>