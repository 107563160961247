<template>
  <div class="dashboard container">
    <a-layout-content>
      <div class="content">
        <a-row class="mt-3" type="flex" justify="center">
          <a-col :xs="24" :md="12">
            <router-view></router-view>
          </a-col>
        </a-row>
      </div>
    </a-layout-content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadCrumbs: [],
    };
  },
  computed() {
    
  },
};
</script>

<style lang="scss" scoped>
</style>