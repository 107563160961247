<template>
  <div>
    <a-layout-header >
      <div class="logo"></div>
      <a-row class="container">
        <a-col :xs="20">
          <a-menu
            mode="horizontal"
            :selectedKeys="currentPath"
            :style="{ lineHeight: '64px' }"
            @click="routeHasChanged"
            onLoad="routeHasChanged"
          >
          <a-menu-item key="Logo"><router-link to="/"><img src="../assets/images/vgonly.png" style="width:80%;"></router-link></a-menu-item>
            <a-menu-item key="Home"><router-link to="/"><strong>Home</strong></router-link></a-menu-item>
            <a-menu-item key="Dashboard"><router-link to="/dashboard"><strong>Dashboard</strong></router-link></a-menu-item>
            <a-menu-item key="Contact"><router-link to="/contact"><strong>Contact</strong></router-link></a-menu-item>
          </a-menu>
        </a-col>
        <a-col :xs="4">
          <a-menu 
            mode="horizontal"
            >
            <a-menu-item key="Login"
              ><router-link v-if="!loggedIn" to="/login">Login</router-link>
              <router-link v-else @click="logout" to="/logout">Logout</router-link>
            </a-menu-item>
          </a-menu>
        </a-col>
      </a-row>
    </a-layout-header>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { authComputed } from "../vuex/helpers.js";
export default defineComponent({
  data()
  {
    const currentPath = [this.$router.currentRoute._rawValue.name];  // Gets the current path when the Navigation is loaded.
    // TODO: Wrap Auth Checking into single method?  
    return {
      currentPath,

    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    
    routeHasChanged() {
      this.currentPath = [this.$router.currentRoute._rawValue.name];
      return;
    }
  },
});
</script>

<style lang="scss" scoped>
</style>