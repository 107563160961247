<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin">Admin</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>Users</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />
    
    <AdminMenu />

    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="24">
        <a-card title="Users" type="primary">   
          <template #extra>
            <router-link to="/admin/newuser">New User</router-link>
          </template>       
          <a-table :dataSource="users" :columns="columns">   
            
            <template #name="{ record }">
              {{ record.firstName }} {{ record.lastName }}
            </template>
            <template #actions="{ record }">
              <a-button @click="disable(record.id)">Disable</a-button>
            </template>
          </a-table>      
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import axios from "axios";
import AdminMenu from "../../components/AdminMenu.vue"

export default {
  components:
  {
    AdminMenu
  },
  data() {
    return {
      users: [],
    };
  },
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  setup()
  {
    return {
      columns: [
        {
          title: 'Name',
          dataIndex: 'firstName',
          key: 'firstName',
          slots: { customRender: 'name' },
        }, 
        {
          title: 'Email',
          dataIndex: 'email',
        },
        {
          title: '',
          key: 'action',
          slots: { customRender: 'actions' },
        },  
      ]
    }
  },
  created() {
    //const headers = { "Content-Type": "application/json" };
    axios
      .get(
        `${this.api}/User`
      )
      .then((response) => {
        this.users = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    

  },
  methods: {
    
  },
};
</script>
