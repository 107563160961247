<template>
  <div class="fonts">
    <a-layout-content>
      <div class="text-center">
        <div class="mb-3">
          <b>Heading 1</b>
          <h1>Welcome to Metis</h1>
        </div>
        <div class="mb-3">
          <b>Heading 2</b>
          <h2>Viv Greene</h2>
        </div>
        <div class="mb-3">
          <b>Heading 3</b>
          <h3>Welcome to Metis</h3>
        </div>
        <div class="mb-3">
          <b>Heading 4</b>
          <h4>Welcome to Metis</h4>
        </div>

        <hr/>

        <div class="mb-3">
          <b>Subheading</b>
          <span class="subheading">This is a Subheading</span>
        </div>

        <div class="mb-3">
          <b>Body</b>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis id massa varius, dignissim dui a, ornare justo. Ut mattis tincidunt interdum. Pellentesque ac sapien ut risus lobortis semper. Phasellus magna sem, tincidunt non convallis sit amet, interdum quis nibh. Morbi auctor porttitor lectus sit amet luctus. Nunc dapibus nisl sapien, eu rutrum arcu cursus nec. Mauris ornare purus imperdiet blandit gravida. Phasellus vel mollis dolor. Nam euismod nibh odio, at fermentum ligula mattis quis. Suspendisse in auctor tortor. </p>
        </div>

      </div>
    </a-layout-content>
  </div>
</template>

<script>
</script>
