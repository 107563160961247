<template>
  <div class="content">
    <a-row class="mt-2" type="flex" justify="center">
      <a-col :xs="16">
        <h1>Welcome to VGA POPIA platform</h1><hr/>
        <img src="../assets/images/pexels.jpg" style="width:300px; float: left; margin-right: 30px;">
        <p>POPIA is a South African law that applies to the processing of personal information. If you have a business in either or both the private or public sector, 
        you <strong>need</strong> to comply.</p>
    <p>
    We understand that the admin that goes into compliance is tedious and time-consuming.
    To combat this, we've created a platform for you with all of the documents that you need in order to be compliant.
    </p>
    <p>
    We've done away with the need for numerous to and from emails and phone calls - the platform asks you for what it needs and generates the necessary document, ready to be downloaded, in mere seconds.
    What was a month long process can now be achieved in 10 minutes.
    </p> 
    <p>Login, save time, secure your future.</p>
        </a-col>
    </a-row>
  </div>
</template>
