<template>
    <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin">Admin</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>New User</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />

    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="24">
        
        <a-button class="mb-2" type="secondary" @click="$router.push('/admin/users')">Go Back</a-button>
        <a-card title="New User" type="primary">          
          <form @submit.prevent="register">
            <label for="name"> First Name: </label>
            <input
              v-model="firstname"
              type="text"
              name="firstname"
              class="ant-input"
              value
            />
            <label for="name"> Last Name: </label>
            <input
              v-model="lastname"
              type="text"
              name="lastname"
              class="ant-input"
              value
            />
            <label for="email"> Email: </label>
            <input
              v-model="email"
              type="email"
              name="email"
              class="ant-input"
              value
            />

            <label for="password"> Password: </label>
            <a-input-password
              v-model:value="password"
              type="password"
              name="password"
            />

            <label for="companyName"> Company Name: </label>
            <input
              v-model="companyName"
              type="text"
              name="companyName"
              class="ant-input"
              value
            />
            <label for="companyAddress"> Company Address: </label>
            <input
              v-model="companyAddress"
              type="text"
              name="companyAddress"
              class="ant-input"
              value
            />
            <label for="companyReg"> Company Reg. Number: </label>
            <input
              v-model="companyReg"
              type="text"
              name="companyReg"
              class="ant-input"
              value
            />
            <label for="companyTel"> Company Tel: </label>
            <input
              v-model="companyTel"
              type="text"
              name="companyTel"
              class="ant-input"
              value
            />

            <button class="mt-1 ant-btn" type="submit" name="button">Register</button>
          </form>
        </a-card>
      </a-col>
    </a-row>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      companyName: "",
      companyAddress: "",
      companyReg: "",
      companyTel: ""
    };
  },
  methods: {
    register() {
      this.$store.dispatch("register", {
        firstName: this.firstname,
        lastName: this.lastname,
        email: this.email,
        password: this.password,
        companyName: this.companyName,
        companyPhysicalAddress: this.companyAddress,
        companyRegNumber: this.companyReg,
        companyTelephoneNumber: this.companyTel
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>