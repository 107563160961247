import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Contact from "../views/Contact.vue";
//import Login from "../views/Login.vue"; //Old
import UserManager from "../views/UserManager.vue";
import LoginUser from "../views/usermanager/LoginUser.vue";
import Components from "../views/Components.vue";
import Company from "../views/Admin/Company.vue";
import Template from "../views/Template.vue";
import Fonts from "../views/components/Fonts.vue";
import Buttons from "../views/components/Buttons.vue";
import Inputs from "../views/components/Inputs.vue";
import Modals from "../views/components/Modals.vue";

//Admin
import Admin from "../views/Admin.vue";
import AdminCompanies from "../views/Admin/Companies.vue";
import AdminUsers from "../views/Admin/Users.vue";
import AdminCategories from "../views/Admin/Categories.vue";
import AdminTemplates from "../views/Admin/Templates.vue";
import AdminTemplate from "../views/Admin/Template.vue";
import AdminCreateTemplate from "../views/Admin/CreateTemplate.vue";
import AdminTemplateFields from "../views/Admin/TemplateFields.vue";
import AdminRegisterUser from "../views/Admin/RegisterUser.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/template/:id",
    name: "Template",
    component: Template,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/companies",
    name: "Companies",
    component: AdminCompanies,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/users",
    name: "Users",
    component: AdminUsers,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/newuser",
    name: "New User",
    component: AdminRegisterUser,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/company/:id",
    name: "Company",
    component: Company,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/categories",
    name: "Categories",
    component: AdminCategories,
    props: false,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/templates",
    name: "Templates",
    component: AdminTemplates,
    props: false,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/templates/:catid",
    name: "Cat Templates",
    component: AdminTemplates,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/templates/create",
    name: "Create Template",
    component: AdminCreateTemplate,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/templatefields",
    name: "Template Fields",
    component: AdminTemplateFields,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/template/:id",
    name: "Admin Template",
    component: AdminTemplate,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/usermanager",
    component: UserManager,
    children: [
      {
        path: "/login",
        name: "Login",
        component: LoginUser,
      },
    ],
  },
  {
    path: "/components",
    component: Components,
    children: [
      {
        path: "fonts",
        component: Fonts,
      },
      {
        path: "buttons",
        component: Buttons,
      },
      {
        path: "inputs",
        component: Inputs,
      },
      {
        path: "modals",
        component: Modals,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

//TODO: Strict enforce of Logged in Verification
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next("/login");
      return;
    }
    next();
  }

  next();
});
