<template>
  <div class="dashboard container">
    <a-layout-content>
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>Home</a-breadcrumb-item>
          <a-breadcrumb-item v-for="bc in this.breadcrumbs" :key="bc">{{ bc }}</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="content">
       <router-view></router-view>
      </div>
    </a-layout-content>
  </div>
</template>

<script>
import {useRoute} from 'vue-router'
import {computed} from 'vue'

export default {
  data() {
    return {
      breadCrumbs: []
    }
  },
  computed(){
    const route = computed(() =>useRoute().path)
    this.breadCrumbs = route.value.split("/");
    
  }

}
</script>
