<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>Admin</a-breadcrumb-item>
      <a-breadcrumb-item>Contacts</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />

    <AdminMenu />


  <a-card title="Contact Submissions">
    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      
      <a-col :xs="24">
      <b>Pending</b>
       <a-table :dataSource="contactsPending" :columns="columns">   
          <template #actions="{ record }">
            <a-button @click="changeStatus(record.id)">Mark Read</a-button>
            <a-button class="ml-1" @click="currentSubmission = record; showModal = true">View</a-button>
          </template>
        </a-table>   
      </a-col>
    </a-row>
    <hr/>
    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="24">
        <b>Completed</b>
       <a-table :dataSource="contactsDone" :columns="columns">   
          <template #actions="{ record }">
            <a-button @click="changeStatus(record.id)">Mark Unread</a-button>
            <a-button class="ml-1" @click="currentSubmission = record; showModal = true">View</a-button>
            <a-button class="ml-1" type="secondary" @click="deleteItem(record.id)">Delete</a-button>
          </template>
        </a-table>   
      </a-col>
      
    </a-row>
  </a-card>
  </div>
  <a-modal :visible="showModal" @ok="showModal = false"  @cancel="showModal = false" :title="currentSubmission.contactSubject" cancelText="">
    <b>Name</b><br/>
    {{currentSubmission.contactName}}
    <br/>
    <b>Email</b><br/>
    {{currentSubmission.contactEmail}}
    <br/>
    <b>Contact</b><br/>
    {{currentSubmission.contactPhone}}
    <br/>
    <br/>
    
    <p>
    <b><u>{{currentSubmission.contactSubject}}</u></b><br/>
    {{currentSubmission.contactMessage}}
    </p>
  </a-modal>
</template>

<script>
import axios from "axios";
import { notification } from 'ant-design-vue';
import AdminMenu from '../components/AdminMenu.vue';

export default {
  components:
  {
    AdminMenu
  }, 
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  data() {
    return {
      companies: {},
      contactsDone: [],
      contactsPending: [],
      currentSubmission: {},
      showModal: false
    };
  },
  setup()
  {
    return {
      columns: [
        {
          title: 'Name',
          dataIndex: 'contactName',
          key: 'contactName',
        }, 
        {
          title: 'Email',
          dataIndex: 'contactEmail',
          key: 'contactEmail',
        }, 
        {
          title: 'Contact',
          dataIndex: 'contactPhone',
          key: 'contactPhone',
        }, 
        {
          title: '',
          key: 'action',
          slots: { customRender: 'actions' },
        },  
      ]
    }
  },
  created() {
    //const headers = { "Content-Type": "application/json" };
    axios
      .get(
        `${this.api}/Company`
      )
      .then((response) => {
        this.companies = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${this.api}/contact/false`
      )
      .then((response) => {
        this.contactsPending = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${this.api}/contact/true`
      )
      .then((response) => {
        this.contactsDone = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    

  },
  methods: {
    refreshData() {

    axios
      .get(
        `${this.api}/contact/false`
      )
      .then((response) => {
        this.contactsPending = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${this.api}/contact/true`
      )
      .then((response) => {
        this.contactsDone = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    
    changeStatus(id)
    {
      axios
        .put(
          `${this.api}/Contact/changestatus/` + id
        )
        .then(() => {
          this.refreshData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    deleteItem(id)
    {
      axios
        .delete(
          `${this.api}/Contact/` + id
        )
        .then(() => {
          notification.open({
            message: "Deleted contact item.",
            placement: "bottomRight"
          })
          this.refreshData();
        })
        .catch((error) => {
          notification.open({
            message: "Error deleting item.",
            placement: "bottomRight"
          })
          console.log(error);
        });
    }
  },
};
</script>
