<template>
  <div class="fonts">
    <a-layout-content>
      <div class="mb-3 text-center">
        <h1>Inputs</h1>
      </div>
      <div class="mb-3">
        <label>Text Input</label>
        <a-input placeholder="Text Input" />
      </div>
      <div class="mb-3">
        <label>Password Input</label>
        <a-input-password  placeholder="Text Input" />
      </div>
      <div class="mb-3">
        <label>Prefixed and/or suffixed</label>
        <a-input addon-before="@" addon-after=".com" />
      </div>
      <div class="mb-3">
        <label>Prefixed with icons</label>
        <a-input>
          <template #addonBefore>
            <i class='far fa-envelope'></i>
          </template>
        </a-input>
      </div>
      <div class="mb-3">
        <label>Text Area</label>
        <a-textarea :rows="4"/>
      </div>
      <div class="mb-3">
        <label>Date Input</label>
        <a-input type="date"  placeholder="Text Input" />
      </div>
    </a-layout-content>
  </div>
</template>

<script>

</script>
