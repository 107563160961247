<template>
  <div>
    
    <a-row class="mb-2" :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="12" :md="5">
        <router-link to="/admin/" class="ant-btn ant-btn-primary" style="width: 100%; line-height: 50px; height: 50px;">CONTACTS</router-link>
      </a-col>
      <a-col :xs="12" :md="5">
        <router-link to="/admin/companies" class="ant-btn ant-btn-primary" style="width: 100%; line-height: 50px; height: 50px;">COMPANIES</router-link>
      </a-col>
      <a-col :xs="24" :md="5">
        <router-link to="/admin/categories" class="ant-btn ant-btn-primary" style="width: 100%; line-height: 50px; height: 50px;">TEMPLATE CATEGORIES</router-link>
      </a-col>
      <a-col :xs="24" :md="5">
        <router-link to="/admin/templatefields" class="ant-btn ant-btn-primary" style="width: 100%; line-height: 50px; height: 50px;">TEMPLATE FIELDS</router-link>
      </a-col>
      <a-col :xs="24" :md="4">
        <router-link to="/admin/users" class="ant-btn ant-btn-primary" style="width: 100%; line-height: 50px; height: 50px;">USERS</router-link>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
 
};
</script>