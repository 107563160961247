<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin">Admin</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>Template Categories</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />
    
    <AdminMenu/>
    
    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="24">
        <a-card title="Template Categories" type="secondary">          
          <a-table :dataSource="categories" :columns="columns">   
            
            <template #name="{ record }">
              <router-link :to="'/admin/templates/' + (record.id ?? '')">{{ record.templateCategoryName }}</router-link>
            </template>
            
          </a-table>      
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import axios from "axios";
import AdminMenu from '../../components/AdminMenu.vue';

export default {
  components:
  {
    AdminMenu
  },
  data() {
    return {
      categories: [],
    };
  },
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  setup() {
    return {
      columns: [
        {
          title: 'Name',
          dataIndex: 'templateCategoryName',
          key: 'templateCategoryName',
          slots: { customRender: 'name' }
        },
        {
          title: 'Description',
          dataIndex: 'templateCategoryDescription',
          key: 'templateCategoryDescription'
        }        
      ]
    }
  },
  created() {
    //const headers = { "Content-Type": "application/json" };
    axios
      .get(
        `${this.api}/Company/category`
      )
      .then((response) => {
        this.categories = response.data;
        this.categories.push({
          templateCategoryName: "ALL TEMPLATES"
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    
  },
};
</script>
