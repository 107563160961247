<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item>Home</a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/dashboard">Template</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ template[0].templateCompanyFields.templateName }}</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />
    <a-card :title="template[0].templateCompanyFields.templateName">
      <template #extra>
        <button class="ant-btn ant-btn-secondary" v-on:click="saveAndDownload()">Download</button>
      </template>
      <div v-if="!isSaving">
        <a-row class="mb-2" v-for="field in template" v-bind:key="field.id">
          <a-col :xs="24">
            <b>{{ field.templateFieldDescription }}</b><br/>
            <a-input v-model:value="field.templateCompanyFields.companyFieldValue"/>
          </a-col>
        </a-row>
        <a-row class="mb-2" >
          <a-col :xs="24">
            <a-button type="secondary" @click="saveAndGoBack()">Done</a-button>
            <a-button style="float: right" type="primary" @click="save()">Save</a-button>
          </a-col>
        </a-row>
      </div>
          
      <a-spin v-else />
    </a-card>

  </div>
</template>

<script>
import axios from "axios";
import router from "../router";

import { notification } from 'ant-design-vue';

import store from '../store';

export default {
  props: {
    id: { required: true },
  },
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  data() {
    return {
      isEditing: false,
      template: [
        {
          templateCompanyFields: {        
              companyFieldValue: ""
            }
        }
      ],
      isSaving: false
    };
  },
  created() {
    //const headers = { "Content-Type": "application/json" };
    axios
      .get(
        `${this.api}/Client/template/${this.id}`
      )
      .then((response) => {
        this.template = response.data;
        this.template.forEach((t) => {
          if(t.templateCompanyFields == undefined)
          {
            t['templateCompanyFields'] = {};
            t['templateCompanyFields']['companyFieldValue'] = "";
          }
        })
      })
      .catch(() => {
        
      });
  },
  methods: {
    async saveAndGoBack()
    {
      await this.save();
      router.back();
    },
    async saveAndDownload()
    {
      await this.save();
      this.templateDownload();
    },
    async save() {
      this.isSaving = true;
      this.template.forEach((field) => {
        if(field != undefined)
        {
          axios
            .put(`${this.api}/Client/template/`, 
              {
                "companyId": 0,
                "templateFieldId": field.id,
                "companyFieldName": field.templateFieldName,
                "companyFieldValue": (field.templateCompanyFields != undefined ? field.templateCompanyFields.companyFieldValue : "")
              }
            )
            .then(() => {
              
            })
            .catch(() => {
              
            });
        }
      });
      notification.open({
        message: "Saved",
        placement: "bottomRight"
      });
      
      this.isSaving = false;
      //router.push("/dashboard");
    },
    templateDownload() {
      notification.open({
            message: "Preparing template file...",
            placement: "bottomRight"
          })

      axios({
        url: `${this.api}/company/word/` + store.state.companyId + "/" + this.id,
        method: "GET",
        headers: {
          Accept: "*/*"         
        },
        responseType: "arraybuffer"
      }).then((response) => {
        
        var fileURL = URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        
        fileLink.setAttribute("download", this.template[0].templateCompanyFields[0] != undefined ? this.template[0].templateCompanyFields[0].templateFileName : this.template[0].templateCompanyFields.templateFileName);
        document.body.appendChild(fileLink);

        fileLink.click();
        notification.open({
            message: "Template File is ready for download.",
            placement: "bottomRight"
          })
      })
      .catch((ex) => {
        if(ex.response.statusCode == 400)
        {
          notification.open({
            message: ex.response.data.title,
            type: 'error'
          })
        }
      });
    }
  },
};
</script>
