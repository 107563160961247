<template>
  <div class="fonts">
    <a-layout-content>
      <div class="text-center">
        <div class="mb-3">
          <h1>Buttons</h1>
        </div>
        <div class="mb-3">
          <a-button class="mr-1" >Primary</a-button>
          <a-button class="mr-1" type="secondary">Secondary</a-button>
          <a-button class="mr-1"  type="light">Light</a-button>
          <a-button class="mr-1"  type="dark">Dark</a-button>
          <a-button class="mr-1"  type="success">Success</a-button>
          <a-button class="mr-1"  type="warning">Warning</a-button>
          <a-button class="mr-1"  type="danger">Danger</a-button>
        </div>
        <div class="mb-3">
          <a-button class="mr-1"  disabled=true>Primary</a-button>
          <a-button class="mr-1"  disabled=true type="secondary">Secondary</a-button>
          <a-button class="mr-1"  disabled=true type="light">Light</a-button>
          <a-button class="mr-1"  disabled=true type="dark">Dark</a-button>
          <a-button class="mr-1"  disabled=true type="success">Success</a-button>
          <a-button class="mr-1"  disabled=true type="warning">Warning</a-button>
          <a-button class="mr-1"  disabled=true type="danger">Danger</a-button>
        </div>

        <div class="mt-5 mb-3">
          <a-button class="rnd mr-1">Primary</a-button>
          <a-button class="rnd mr-1" type="secondary">Secondary</a-button>
          <a-button class="rnd mr-1" type="light">Light</a-button>
          <a-button class="rnd mr-1" type="dark">Dark</a-button>
          <a-button class="rnd mr-1" type="success">Success</a-button>
          <a-button class="rnd mr-1" type="warning">Warning</a-button>
          <a-button class="rnd mr-1" type="danger">Danger</a-button>
        </div>
        
        <div class="mt-5 mb-3">
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link ant-btn" @click.prevent>
              Dropdown
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="#">Item</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="#">Item</a>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item>
                  <a href="#">Divided Item</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
            <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link ant-btn ant-btn-secondary ml-1" @click.prevent>
              Dropdown
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="#">Item</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="#">Item</a>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item>
                  <a href="#">Divided Item</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>

       </div>
      </div>
    </a-layout-content>
  </div>
</template>

<script>
</script>
