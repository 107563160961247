<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin">Admin</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>Templates</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />
    
    <AdminMenu/>
    
    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="24">
        <a-card :title="'Templates - ' + (category.templateCategoryName ?? 'All')" type="secondary">       
          <template #extra>
            <a-button type="secondary" @click="$router.push('/admin/categories')">Back</a-button>
          </template>   
          <a-table :dataSource="templates" :columns="columns">   
            <template #templateName="{ record }">
              <span>{{ record.templateName }}</span>
            </template>
            <template #templateFileName="{ record }">
              <span>{{ record.templateFileName }}</span>
            </template>
            <template #templateVersion="{ record }">
              <span>{{ record.templateVersion }}</span>
            </template>
            <template #templateLastUpdate="{ record }">
              <span>{{ $filters.standardDateTime( record.templateLastUpdate ) }}</span>
            </template>
            <template #templateFieldLink="{ record }">
              <span>{{ record.templateFieldLink.Length }}</span>
            </template>
            <template #actions="{ record }">
              <router-link :to="{path: `/admin/template/${record.id}`}">Edit</router-link>
            </template>
          </a-table>      
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import axios from "axios";
import AdminMenu from '../../components/AdminMenu.vue';

export default {
  components:
  {
    AdminMenu
  },
  props: [
    'catid'
  ],
  data() {
    return {
      templates: [],
      category: {},
      categories: []
    };
  },
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  setup() {
    return {
      columns: [
        {
          title: 'Template Name',
          dataIndex: 'templateName',
          key: 'templateName',
          slots: { customRender: 'templateName' }
        },
        {
          title: 'Template File',
          dataIndex: 'templateFileName',
          key: 'templateFileName'
        },
        {
          title: 'Version',
          dataIndex: 'templateVersion',
          key: 'templateVersion'
        },
        {
          title: 'Updated',
          dataIndex: 'templateLastUpdate',
          slots: { customRender: 'templateLastUpdate' },
        },    
        {
          title: 'Actions',
          key: 'action',
          slots: { customRender: 'actions' },
        }, 
      ]
    }
  },
  created() {
    //const headers = { "Content-Type": "application/json" };
    axios
      .get(
        `${this.api}/Company/category`
      )
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    if(this.catid)
    {
      axios
        .get(
          `${this.api}/Company/category/${this.catid}`
        )
        .then((response) => {
          this.category = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(
          `${this.api}/Company/templates/category/${this.catid}`
        )
        .then((response) => {
          this.templates = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else
    {

      axios
        .get(
          `${this.api}/Company/templates`
        )
        .then((response) => {
          this.templates = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  methods: { 
    
  },
};
</script>
