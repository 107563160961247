<template>
  <div class="page container">
    <a-breadcrumb>
      <a-breadcrumb-item><router-link to="/">Home</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/admin">Admin</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>Companies</a-breadcrumb-item>
    </a-breadcrumb>
    <hr />

    <AdminMenu />
    
    <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <a-col :xs="24">
        
        <a-card title="Companies" type="primary">          
          <a-table :dataSource="companies" :columns="columns">   
            
            <template #actions="{ record }">
              <router-link :to="{path: `/admin/company/${record.id}`}">View</router-link>
            </template>
          </a-table>      
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import axios from "axios";
import AdminMenu from "../../components/AdminMenu.vue"

export default {
  components:
  {
    AdminMenu
  },
  data() {
    return {
      companies: [],
    };
  },
  inject: {
      api: {
          from: 'APIURL'
      }
  },
  setup()
  {
    return {
      columns: [
        {
          title: 'Company Name',
          dataIndex: 'companyName',
          key: 'companyName',
        }, 
        {
          title: 'Registration #',
          dataIndex: 'companyRegNumber',
          key: 'companyRegNumber',
        }, 
        {
          title: '',
          dataIndex: 'companyRegNumber',
          key: 'companyRegNumber',
        },     
        {
          title: '',
          key: 'action',
          slots: { customRender: 'actions' },
        },  
      ]
    }
  },
  created() {
    //const headers = { "Content-Type": "application/json" };
    axios
      .get(
        `${this.api}/Company`
      )
      .then((response) => {
        this.companies = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    

  },
  methods: {
    
  },
};
</script>
